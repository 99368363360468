// src/pages/Login/LoginPage.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, CssBaseline, Box, Typography, TextField, Button, Avatar, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import socket from "../../../socket/Socket.js";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#01a79e",
		},
		background: {
			default: "#121212",
			paper: "#1d1d1d",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const LoginPage = ({ setAuthenticated }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const usernameRef = useRef("");
	const passwordRef = useRef("");

	useEffect(() => {
		usernameRef.current = username;
		passwordRef.current = password;
	}, [username, password]);

	useEffect(() => {
		const checkAuth = async () => {
			const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
			const token = localStorage.getItem("token");

			if (!selectedAccount || !token) {
				return;
			}

			setAuthenticated(true);
			socket.emit("login", {
				userId: selectedAccount.userId,
				account: selectedAccount.account,
				token: token,
			});
			window.location.href = "/chart";
		};

		checkAuth();
	}, [setAuthenticated]);

	const handleLogin = async (e) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		try {
			const response = await axios.post("https://mitserver.app:9005/login", {
				email: username || usernameRef.current,
				password: password || passwordRef.current,
			});

			if (response.data.ok) {
				const data = response.data.data;
				const token = response.data.token;

				await localStorage.setItem("user", JSON.stringify(data));
				await localStorage.setItem("token", token);
				const selectedAccount = {
					account: data.demoAcc,
					userId: data.id,
					token: data.demoToken,
				};

				await localStorage.setItem("selectedAccount", JSON.stringify(selectedAccount));
				setAuthenticated(true);
				socket.emit("login", {
					userId: data.id,
					token: token,
				});
				await localStorage.setItem("contractCategory", "multiplier");
				window.location.href = "/chart";
			} else {
				console.log("LOGIN ERROR:", response.data.message);
				toast.dark(response.data.message || "Error during login", { pauseOnHover: false });
			}
		} catch (error) {
			console.error("LOGIN ERROR:", error);
		}
	};

	const handleForgotPassword = async () => {
		try {
			if (username === "") {
				toast.dark("Por favor escribe tu correo electrónico", { pauseOnHover: false });
				return;
			}
			const response = await axios.post("https://mitserver.app:9005/forgot", {
				email: username,
			});
			if (response.status === 200) {
				toast.dark(response.data.message || "Contraseña Enviada", { pauseOnHover: false });
			} else {
				toast.dark(response.data.message || "Error al enviar correo", { pauseOnHover: false });
			}
		} catch (error) {
			toast.dark(error.response?.data?.message || "Un error ha ocurrido. Por favor intenta de nuevo.", { pauseOnHover: false });
		}
	};

	useEffect(() => {
		console.log("Componente LoginPage montado");

		const handleLoginFromMessage = () => {
			// Esta función tendrá acceso a los valores actualizados
			handleLogin();
		};

		const handleIncomingMessage = (event) => {
			try {
				const data = typeof event.data === "string" ? JSON.parse(event.data) : event.data;

				if (data.email && data.password) {
					setUsername(data.email);
					setPassword(data.password);
					console.log("Datos recibidos:", data);
					setTimeout(handleLoginFromMessage, 100);
				}
			} catch (error) {
				console.error("Error al parsear el mensaje:", error);
			}
		};

		window.addEventListener("message", handleIncomingMessage);

		return () => {
			window.removeEventListener("message", handleIncomingMessage);
		};
	}, [handleLogin]);

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					backgroundImage: 'url("/assets/bg.jpg")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: { xs: "column", md: "row" },
					padding: { xs: "20px", md: 0 },
				}}>
				<CssBaseline />
				<Container
					component="main"
					maxWidth="xs"
					sx={{
						marginLeft: { xs: "0", md: "40%" },
						width: { xs: "100%", md: "auto" },
					}}>
					<Paper
						elevation={6}
						style={{
							padding: "20px",
							borderRadius: "10px",
							background: "transparent",
						}}>
						<Box
							sx={{
								marginTop: { xs: 2, md: 8 },
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<Box sx={{ m: 1, width: { xs: "70%", md: "100%" } }}>
								<img src="/assets/logoMV.png" alt="Login" width="100%" />
							</Box>
							<Typography
								component="h1"
								variant="h5"
								sx={{
									color: "#fff",
									textShadow: "0 0 10px #4599d9",
									marginTop: { xs: "30px", md: "60px" },
									fontSize: { xs: "1.5rem", md: "h5.fontSize" },
								}}>
								Inicio de Sesión
							</Typography>
							<Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: "100%" }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="username"
									label="E-mail"
									name="username"
									autoComplete="username"
									autoFocus
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									InputLabelProps={{ style: { color: "#ffffff" } }}
									InputProps={{
										style: { color: "#ffffff" },
										sx: {
											"& fieldset": { borderColor: "#4599d9" },
											"&:hover fieldset": { borderColor: "#f74712" },
											"&.Mui-focused fieldset": { borderColor: "#f74712" },
										},
									}}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									InputLabelProps={{ style: { color: "#ffffff" } }}
									InputProps={{
										style: { color: "#ffffff" },
										sx: {
											"& fieldset": { borderColor: "#4599d9" },
											"&:hover fieldset": { borderColor: "#f74712" },
											"&.Mui-focused fieldset": { borderColor: "#f74712" },
										},
									}}
								/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										mt: 3,
										mb: 2,
										backgroundColor: "#4599d9",
										color: "#fff",
										"&:hover": {
											backgroundColor: "#3d87c3",
										},
										boxShadow: "0 0 5px #4599d9, 0 0 5px #4599d9",
									}}>
									Iniciar
								</Button>
								<Typography align="center">
									<Link to="#" onClick={handleForgotPassword} style={{ color: "#f74712", textShadow: "0 0 5px#cc2e3d" }}>
										¿Olvidaste tu contraseña?
									</Link>
								</Typography>
								<Button
									fullWidth
									variant="contained"
									sx={{
										mt: 2,
										mb: 2,
										backgroundColor: "#833ab4",
										color: "#fff",
										"&:hover": {
											backgroundColor: "#833ab4",
										},
										boxShadow: "0 0 5px #833ab4, 0 0 5px #833ab4",
									}}
									onClick={() => (window.location.href = "/register")}>
									Registrarse
								</Button>
							</Box>
						</Box>
					</Paper>
				</Container>
				<Box
					sx={{
						m: 1,
						width: { xs: "70%", md: "auto" },
						display: { xs: "none", md: "block" },
					}}>
					<img src="/assets/ticksy3.png" alt="Login" width="100%" />
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default LoginPage;
